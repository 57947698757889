/***********
 * Helpers
 * @TODO: À ajouter sur Trilogy 🎁
 */

.is-flex-col {
    flex-direction: column;
}

.is-flex-row {
    flex-direction: row !important;
}

.w-auto {
    width: auto;
}

.w-fit {
    width: fit-content !important;
}

.min-w-fit {
    min-width: fit-content;
}

.h-auto {
    height: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}

.is-backgroundless {
    background-color: transparent !important;
}

.is-relative {
    position: relative;
}

.is-force-overflow-wrap {
    overflow-wrap: anywhere;
}

.capitalize-first::first-letter {
    text-transform: uppercase !important;
}

/***********
 * Custo interne
 */

.gap-huge {
    gap: 3rem !important;
}

.gap-small {
    gap: 0.5rem !important;
}

.gap-medium {
    gap: 1rem !important;
}

.gap-smaller {
    gap: 0.2rem !important;
}

.gap-radio-list {
    gap: 0.8rem !important;
}

.mb-small {
    margin-bottom: 0.5rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.is-content-end {
    align-content: flex-end !important;
}

.z-index-2 {
    z-index: 200 !important;
}

.z-index-4 {
    z-index: 400 !important;
}

.z-index-6 {
    z-index: 600 !important;
}

.z-index-8 {
    z-index: 800 !important;
}

.button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
}

.has-no-text-decoration {
    text-decoration: none !important;
}

.has-overflow-hidden {
    overflow: hidden !important;
}

.is-right-0 {
    right: 0;
}

.is-top-0 {
    top: 0;
}

.\!is-fullwidth {
    width: 100% !important;
}

.alert-iconless .icon {
    display: none;
}

.alert-iconless .body {
    padding: 0;
}

.is-paddingless-bottom {
    padding-bottom: 0 !important;
}

.accessory-image {
    width: auto !important;
    height: 14rem !important;
}

@media screen and (max-width: 767px) {
    .is-flex-col-mobile {
        flex-direction: column;
    }

    .is-fullwidth-mobile {
        padding: 0 !important;
    }

    .is-radiusless-mobile {
        border-radius: 0 !important;
    }

    .is-paddingless-mobile {
        padding: 0 !important;
    }

    .remove-section-padding {
        margin-right: -16px !important;
        margin-left: -16px !important;
    }

    .gap-huger-mobile {
        gap: 4rem !important;
    }

    .gap-big-mobile {
        gap: 1.6rem !important;
    }

    .gap-smaller-mobile {
        gap: 0.2rem !important;
    }

    .promo-configurator .column.is-narrow {
        flex: unset !important;
    }

    .is-justified-start-mobile {
        align-items: flex-start !important;
    }

    .is-order-reversed-mobile {
        flex-direction: column-reverse !important;
    }

    .roc-offer-info {
        margin-bottom: 8px;
    }
}

@media screen and (min-width: 1024px) {
    .desktop-justify-end {
        justify-content: end !important;
    }

    .is-sticky-top-desktop {
        position: sticky !important;
        top: 0;
        z-index: 998;
    }
}

@media screen and (max-width: 1023px) {
    .is-flex-col-touch {
        flex-direction: column;
    }

}

@media screen and (min-width: 768px) {
    .md\:order-2 {
        order: 2;
    }

    .gap-medium-tablet {
        gap: 1rem !important;
    }

    .sticker-row {
        height: 40px;
    }

    .roc-nuggets {
        min-height: 202px;
    }

    .roc-nuggets.four-nuggets {
        min-height: 220px;
    }

    .price-description {
        height: 18px;
    }

    .roc-title {
        min-height: 140px;
    }

    .roc-offer-info {
        min-height: 64px !important;
    }

    .roc-sticker {
        height: 24px;
    }

    .roc-cta {
        min-height: 46px;
    }
}

.is-dashed {
    border-radius: 8px;
    border: 2px dashed #d1d1d1;
    background: #fff;
}

.border-dashed-tertiary {
    border: 2px dashed #0a6e81;
}

.rounded-col {
    border-radius: 8px;
}

.fai-offer-card {
    max-width: 44rem;
    min-width: 32rem;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 0;
}

.-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
}

.rows .growless {
    flex-grow: 0 !important;
}

.min-height-sticker {
    min-height: 60px !important;
}

.sticky-bar {
    position: fixed;
    z-index: 900;
    width: 100%;
    transition: 0.25s transform ease;
}

.sticky-top {
    transform: translateY(-150%);
    top: 0;
}

.sticky-top.visible {
    transform: translateY(0);
}

.sticky-bottom {
    bottom: 0;
    transform: translateY(100%);
}

.sticky-bottom .box {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.sticky-bottom.visible {
    transform: translateY(0);
}

.sticky-top .box {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.sticky-top.visible {
    transform: translateY(0);
}

.roc-options {
    align-items: baseline;
}

.price-and-big-icon {
    position: relative;
    width: fit-content;
    margin: 0 auto;
}

.price-and-big-icon .big-icon {
    position: absolute;
    right: -7rem;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
}

.roc-radio-tile {
    max-width: 100% !important;
    background-color: #fff !important;
    border-color: #fff !important;
    border-radius: 8px !important;
    display: block !important;
    margin-top: 0 !important;
}

.roc-radio-tile .radio-label {
    padding: 16px !important;
}

.roc-radio-tile .radio-label:hover {
    background: white !important;
    border-color: #25465F !important;
    border-width: 2px;
}

.roc-radio-tile .radio-label::before {
    z-index: 7999;
}

.is-loading p,
.is-loading span {
    border-radius: 8px !important;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.text-nowrap {
    white-space: nowrap;
}

.text-wrap {
    white-space: normal;
}

.has-scroll-smooth {
    scroll-behavior: smooth;
}

.has-hat.has-top-radius {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.box.is-narrow {
    padding: 12px;
}

.button.is-narrow {
    width: fit-content !important;
}

.rounded-lg {
    border-radius: 8px !important;
}

.h-0 {
    height: 0;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.loader {
    width: 54px;
    height: 27px;
    margin: 4.5px 20px 58.5px 20px;
    background-color: transparent;
    position: relative;
}

.loader span {
    width: 54px;
    height: 27px;
    border-radius: 50%;
    position: relative;
    animation: 2.5s linear 0s forwards infinite top_item;
    background-color: #0C7B91;
}

.loader:before {
    width: 18px;
    height: 18px;
    left: calc(50% - 8px);
    background-color: #25465F;
    animation: 2.5s linear 0s forwards infinite left_item;
    z-index: 1;
}

.loader:after {
    width: 18px;
    height: 18px;
    right: calc(50% - 18px);
    background-color: #CC4E0A;
    animation: 2.5s linear 0s forwards infinite right_item;
}

.loader:after, .loader:before, .loader span {
    content: "";
    display: block;
    border-radius: 50%;
}

.loader:after, .loader:before {
    position: absolute;
    bottom: -36px;
}


@keyframes top_item {
    0% {
        visibility: visible;
        transform: scale(0)
    }

    60% {
        visibility: visible;
        transform: scaleY(0)
    }

    65%, to {
        visibility: visible;
        transform: scale(1)
    }
}

@keyframes left_item {
    0% {
        transform: rotate(55deg) scale(1)
    }

    45% {
        width: 54px;
        height: 27px;
        transform: rotate(55deg) scale(1)
    }

    50%, 54% {
        transform: rotate(55deg) scale(1.5)
    }

    60% {
        transform: rotate(55deg) scale(1)
    }

    65%, to {
        transform: rotate(55deg) scale(1);
        width: 54px;
        height: 27px;
        left: -25px
    }
}

@keyframes right_item {
    0% {
        transform: rotate(-55deg) scale(1)
    }

    45% {
        width: 54px;
        height: 27px;
        transform: rotate(-55deg) scale(1)
    }

    50%, 54% {
        transform: rotate(-55deg) scale(1.5)
    }

    60% {
        transform: rotate(-55deg) scale(1)
    }

    65%, to {
        transform: rotate(-55deg) scale(1);
        width: 54px;
        height: 27px;
        right: -25px
    }
}

.image-cover {
    height: 100%;
    background-size: cover;
    background-position: center;
}

.box-image-cover {
    margin: -24px;
}

.inherit-hero-box {
    box-shadow: rgba(99, 99, 99, .2) 0 2px 8px 0 !important;
    border-radius: 8px !important;
}

.p-small {
    padding: 8px !important;
}

.centered-switch > label {
    padding-right: 40px !important;
}

.sticky-box {
    z-index: 999 !important;
    transition: 0.25s all ease;
}

.sticky-box.hidden {
    transform: translateY(-500px);
    height: 0;
}

.sticky-box > .box .box-content:only-child {
    border-radius: 0 0 8px 8px;
}

.has-custom-big-background {
    background-color: #eaf2f4 !important;
    padding: 10px;
    border-radius: 8px;
}

.promo-configurator {
    position: sticky;
    top: 0;
    z-index: 1;
}

.is-fixed-top {
    position: fixed !important;
    top: 0;
    z-index: 999;
}

.roc-image {
    object-fit: contain;
    width: 100%;
    max-width: 100px;
    height: 80px;
}

.\!is-marginless {
    margin: 0 !important;
}
